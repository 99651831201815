import { css, FlattenInterpolation, FlattenSimpleInterpolation } from 'styled-components'


export enum FontWeights {
  Light = 300,
  Book = 400,
  Bold = 700,
  Wide = 999
}

const lookupFamilyByWeight = {
  [FontWeights.Light]: 'Helvetica Neue Light',
  [FontWeights.Book]: 'Helvetica Neue Roman',
  [FontWeights.Bold]: 'Helvetica Neue Bold',
  [FontWeights.Wide]: 'Helvetica Neue Wide'
}

export enum FontSizes {
  H1 = 'hyper1',
  H2 = 'hyper2',
  H3 = 'hyper2',
  H4 = 'hyper2',
  T1 = 'title1',
  T2 = 'title2',
  T3 = 'title3',
  T4 = 'title4',
  T5 = 'title5',
  T6 = 'title6',
  FinePrint = 'fineprint',
}

const lookupFontSize = {
  [FontSizes.H1]: {
    fontSize: '3.75rem',
    lineHeight: '1.2738',
  },
  [FontSizes.H2]: {
    fontSize: '3rem',
    lineHeight: '1.2738',
  },
  [FontSizes.H3]: {
    fontSize: '2.25rem',
    lineHeight: '1.2738',
  },
  [FontSizes.H4]: {
    fontSize: '1.875rem',
    lineHeight: '1.2738',
  },
  [FontSizes.T1]: {
    fontSize: '1.5rem',
    lineHeight: '1.2738',
  },
  [FontSizes.T2]: {
    fontSize: '1.125rem',
    lineHeight: '1.2738',
  },
  [FontSizes.T3]: {
    fontSize: '0.875rem',
    lineHeight: '1.2738',
  },
  [FontSizes.T4]: {
    fontSize: '0.75rem',
    lineHeight: '1.2738',
  },
  [FontSizes.T5]: {
    fontSize: '0.625rem',
    lineHeight: '1.2738',
  },
  [FontSizes.T6]: {
    fontSize: '0.5625rem',
    lineHeight: '1.2738',
  },
  [FontSizes.FinePrint]: {
    fontSize: '0.5rem',
    lineHeight: '1.2738',
  },
}

export const typeface = (weight: FontWeights) => (size: FontSizes) => css`
  font-family: ${lookupFamilyByWeight[weight]};
  font-size: ${lookupFontSize[size].fontSize};
  line-height: ${lookupFontSize[size].lineHeight};
  margin: ${lookupMargin};
`

type Margin = { top: number, bottom: number }

const lookupMargin: { [key in FontSizes]: Margin } = {
  [FontSizes.H1]: {
    top: -11,
    bottom: -13,
  },
  [FontSizes.H2]: {
    top: -5,
    bottom: -7,
  },
  [FontSizes.H3]: {
    top: -6,
    bottom: -6,
  },
  [FontSizes.H4]: {
    top: -7,
    bottom: -7,
  },
  [FontSizes.T1]: {
    top: -6,
    bottom: -6,
  },
  [FontSizes.T2]: {
    top: -4,
    bottom: -6,
  },
  [FontSizes.T3]: {
    top: -3,
    bottom: -5,
  },
  [FontSizes.T4]: {
    top: -4,
    bottom: -4,
  },
  [FontSizes.T5]: {
    top: -4,
    bottom: -4,
  },
  [FontSizes.T6]: {
    top: -4,
    bottom: -4,
  },
  [FontSizes.FinePrint]: {
    top: -2,
    bottom: -2,
  },
}


export const fontDeclarations = css`
@font-face {
  font-family: 'Helvetica Neue Roman';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/HelveticaNeueLTStd-Roman.eot');
  src: url('/fonts/HelveticaNeueLTStd-Roman.eot?#iefix')
  format('embedded-opentype'),
    url('/fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
      url('/fonts/HelveticaNeueLTStd-Roman.woff') format('woff'),
        url('/fonts/HelveticaNeueLTStd-Roman.ttf') format('truetype'),
          url('/fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman')
  format('svg');
}

@font-face {
  font-family: 'Helvetica Neue Light';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/HelveticaNeueLTStd-Lt.eot');
  src: url('/fonts/HelveticaNeueLTStd-Lt.eot?#iefix')
  format('embedded-opentype'),
    url('/fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
      url('/fonts/HelveticaNeueLTStd-Lt.woff') format('woff'),
        url('/fonts/HelveticaNeueLTStd-Lt.ttf') format('truetype'),
          url('/fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt')
  format('svg');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/HelveticaNeueLTStd-Bd.eot');
  src: url('/fonts/HelveticaNeueLTStd-Bd.eot?#iefix')
  format('embedded-opentype'),
    url('/fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
      url('/fonts/HelveticaNeueLTStd-Bd.woff') format('woff'),
        url('/fonts/HelveticaNeueLTStd-Bd.ttf') format('truetype'),
          url('/fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd')
  format('svg');
}

@font-face {
  font-family: 'Helvetica Neue Wide';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('/fonts/Wide.woff') format('woff');
}
`
export const sansSerif = FontWeights.Book
export const sansSerifWide = FontWeights.Wide

export const fontAssignments = css`
body {
  font-family: ${ sansSerif};
}
body * {
  font-family: ${ sansSerif};
  }
h1, h2, h3, h4, h5, h6 { font - family: ${ sansSerifWide}; }
`

export const globalFontMixin = css`
${ fontDeclarations}
${ fontAssignments}
body {
  font-size: 16px;
  text-size - adjust: 100 %;
  font-kerning: normal;
  font-feature - settings: 'kern', 'liga', 'clig', 'calt';
}
body * {
    -moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
letter-spacing: initial;
text-rendering: optimizelegibility;
touch-action: manipulation;
  }
`
