import React, { TransitionStartFunction } from 'react'

import { DogeList } from './DogeList'
import { AllBreedsDataShape, ApiResponse, Doge, DogeSelector, SelectedDogeState, subbreed, Query } from '../types'
import {
  api,
  comparator,
  compose,
  createResource,
  sort as curriedSort,
  filter as curriedFilter,
  take as curriedTake,
} from '../utils'

const fetchDogeThunk = (): Promise<Array<Doge>> =>
  api.allBreeds()
    .then(r => r.json())
    .then(
      (json: ApiResponse<AllBreedsDataShape>) =>
        Object.keys(json.message).map(
          name => ({
            name,
            subbreeds: json.message[name]
          })))

const dogeResource = createResource<Array<Doge>>(fetchDogeThunk)

interface Props {
  selectDoge: DogeSelector
  selectedDoge: SelectedDogeState
  query: Query
  startTransition: TransitionStartFunction
}

export const DogeResource: React.FC<Props> = ({ selectDoge, selectedDoge, startTransition, query }) => {
  const sort = curriedSort(comparator)
  const filter = curriedFilter(query)
  const take = curriedTake(12)

  const composition = compose([take, filter, sort])
  const allDoge = dogeResource.read()
  const doge = composition(allDoge)

  return doge && <DogeList startTransition={startTransition} selectDoge={selectDoge} selectedDoge={selectedDoge} doge={doge} />
}
