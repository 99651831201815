import React, { Suspense, useState, useCallback } from 'react'

import { DogeResource } from './DogeResource'
import { ErrorBoundary } from './ErrorBoundary'
import { Header } from './Header'
import { ImagesResource } from './ImagesResource'
import { MindBlownEmoji } from './MindBlownEmoji'
import { PreloadedImage } from './PreloadedImage'

import { SelectedDogeState, Query } from '../types'
import { SUSPENSE_CONFIG } from '../config'
import * as S from '../styled'

const DogeFallback = () => <h3>Loading dogs!<PreloadedImage src="/images/doge.gif" alt="Loading doge!" /></h3>
const ImagesFallback = () => <h3>Loading images! <img src="/images/doge.gif" /></h3>

const favicon = document.getElementById('favicon') as any

export const App: React.FC<{}> = () => {
  const [selectedDoge, setSelectedDoge] = useState<SelectedDogeState>(null)
  const [query, setQuery] = useState<Query>('')
  const [startTransition, isPending] = React.useTransition(SUSPENSE_CONFIG)
  const selectDoge = useCallback(setSelectedDoge, [selectedDoge])

  React.useEffect(() => {

    if (isPending && favicon) {
      favicon.href = "haskell.ico"
    }
    else favicon.href = "simspace.ico"
  }, [isPending])

  return (
    <S.Main>
      <S.Container>
        <Header query={query} setQuery={setQuery} />
        <NetworkErrorBoundary>
          <Suspense fallback={<DogeFallback />}>
            <DogeResource
              startTransition={startTransition}
              selectDoge={selectDoge}
              selectedDoge={selectedDoge}
              query={query}
            />
          </Suspense>
          {selectedDoge && (
            <Suspense fallback={<ImagesFallback />}>
              <ImagesResource selectedDoge={selectedDoge} isPending={isPending} />
            </Suspense>
          )}
        </NetworkErrorBoundary>
      </S.Container>
    </S.Main>
  )
}

function NetworkErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary
      render={error => (
        <>
          <S.H1>
            Error fetching data <MindBlownEmoji />
          </S.H1>
          <p>Check your internet connection & try again</p>
        </>
      )}
    >
      {children}
    </ErrorBoundary>
  )
}
