import React from 'react'

import { ImagesList } from './ImagesList'

import { ImageList, SelectedDoge, Resource } from '../types'
import { api, createResource } from '../utils'
import * as S from '../styled'

const fetchImages = (selectedDoge: string): Promise<ImageList> =>
  api.imgsByBreed(selectedDoge).then(r => r.json().then(r => r.message))

const imagesResourceCache: any = {}


const getImagesResource: (breed: SelectedDoge) => Resource<ImageList> = (breed) => {
  if (imagesResourceCache[breed]) return imagesResourceCache[breed]
  const resource = createResource<ImageList>(() => fetchImages(breed))
  imagesResourceCache[breed] = resource
  return resource
}

interface Props {
  selectedDoge: SelectedDoge
  isPending: boolean
}

export const ImagesResource: React.FC<Props> = ({ isPending, selectedDoge }) => {
  const imagesResource = getImagesResource(selectedDoge)

  const images = imagesResource.read()

  return images && <div className={isPending ? 'images-pending' : ''}><ImagesList images={images} /></div>
}
