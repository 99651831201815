import React from 'react'

import { SingleImage } from './SingleImage'
import { ImageList } from '../types'
import * as S from '../styled'

interface Props {
  images: ImageList
}

export const ImagesList: React.FC<Props> = ({ images }) => {
  return <S.Grid col={4}>{images.map(image => <SingleImage key={image} image={image} />)}</S.Grid>
}
