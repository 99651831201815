import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

import { ErrorBoundary } from './components/ErrorBoundary'
import { App } from './components/App'
import { GlobalStyle } from './styled/global'
import * as serviceWorker from './serviceWorker'
import './index.css'

import { AppTheme } from './styled/theme'

const root = document.getElementById('root') as HTMLElement
const AppFallback = () => <h3>Loading page...</h3>

const Root: React.FC<{}> = () => (
  <ThemeProvider theme={AppTheme}>

    <ErrorBoundary>
      <Suspense fallback={<AppFallback />}>
        <GlobalStyle />
        <App />
      </Suspense>
    </ErrorBoundary>
  </ThemeProvider>
)

ReactDOM.createRoot(root).render(<Root />);
serviceWorker.unregister()
