export enum ThemeColors {
  PrimaryColor = 'primary',
  SecondaryColor = 'secondary',
  BackgroundPrimary = 'bgPrimary',
  BackgroundSecondary = 'bgSecondary',
  BodyText = 'body',
  HeaderText = 'header',
}

export enum Colors {
  LightBlue = 'lightBlue',
  DarkBlue = 'darkBlue',
  OffWhite = 'offWhite',
  LightGrey = 'lightGrey',
  Pencil = 'pencil',
  Pen = 'pen',
  FaintGrey = 'faintGrey',
  BlueHighlighter = 'blueHighlighter',
  LightOutline = 'lightOutline',
  White = 'white',
}

const rgba = (r: number, g: number, b: number, a: number = 1) =>
  `rgba(${r}, ${g}, ${b}, ${a})`

export const getColor = (color: Colors): string => {
  const dictionary = {
    [Colors.LightBlue]: rgba(20, 103, 255),
    [Colors.DarkBlue]: rgba(21, 28, 79),
    [Colors.OffWhite]: rgba(230, 229, 229),
    [Colors.LightGrey]: rgba(214, 214, 214),
    [Colors.Pencil]: rgba(0, 0, 0, 0.74),
    [Colors.Pen]: rgba(0, 0, 0, 0.89),
    [Colors.FaintGrey]: rgba(0, 0, 0, 0.36),
    [Colors.BlueHighlighter]: rgba(20, 103, 255, 0.12),
    [Colors.LightOutline]: rgba(0, 0, 0, 0.12),
    [Colors.White]: rgba(255, 255, 255),
  }
  return dictionary[color]
}

export interface AppThemeInterface {
  [ThemeColors.PrimaryColor]: Colors
  [ThemeColors.SecondaryColor]: Colors
  [ThemeColors.BackgroundPrimary]: Colors
  [ThemeColors.BackgroundSecondary]: Colors
  [ThemeColors.BodyText]: Colors
  [ThemeColors.HeaderText]: Colors
  linkColor: Colors
  em: 8 | 16
  button: {
    base: {
      color: Colors
      background: Colors
    }
    active: {
      color: Colors
      background: Colors
    }
  }
}

export const AppTheme: AppThemeInterface = {
  [ThemeColors.PrimaryColor]: Colors.LightBlue,
  [ThemeColors.SecondaryColor]: Colors.DarkBlue,
  [ThemeColors.BackgroundPrimary]: Colors.OffWhite,
  [ThemeColors.BackgroundSecondary]: Colors.LightGrey,
  [ThemeColors.BodyText]: Colors.Pencil,
  [ThemeColors.HeaderText]: Colors.Pen,
  linkColor: Colors.LightBlue,
  em: 16,
  button: {
    base: {
      color: Colors.LightBlue,
      background: Colors.BlueHighlighter,
    },
    active: {
      color: Colors.White,
      background: Colors.LightBlue,
    },
  },
}

export const makeGetterThemeColor = (theme: AppThemeInterface) => (
  key: ThemeColors,
): string => {
  return getColor(theme[key])
}

export const getThemeColor = makeGetterThemeColor(AppTheme)
