
import styled, { css } from 'styled-components'

import { getColor, getThemeColor, Colors } from './theme'
import { cssOverridesTemplateLiteral } from './helpers'
import { typeface, FontSizes } from './typography'
import { InlineProps } from './types'

const flexMixin = css`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  flex-wrap: wrap;
`

const flexCenterMixin = css`
  justify-content: center;
  align-items: center;
`

export const Text = styled.p<InlineProps & { as?: string }>(
  cssOverridesTemplateLiteral,
)
export const Div = styled.div<InlineProps>(cssOverridesTemplateLiteral)
export const P = styled.p<InlineProps>(cssOverridesTemplateLiteral)
export const H1 = styled.h1<InlineProps>(cssOverridesTemplateLiteral)
export const H2 = styled.h2<InlineProps>(cssOverridesTemplateLiteral)
export const H3 = styled.h3<InlineProps>(cssOverridesTemplateLiteral)
export const H4 = styled.h4<InlineProps>(cssOverridesTemplateLiteral)
export const H5 = styled.h5<InlineProps>(cssOverridesTemplateLiteral)
export const H6 = styled.h6<InlineProps>(cssOverridesTemplateLiteral)
export const _A = styled.a<InlineProps>(cssOverridesTemplateLiteral)
export const _Form = styled.form<InlineProps>(cssOverridesTemplateLiteral)
export const _Main = styled.main<InlineProps>(cssOverridesTemplateLiteral)
export const _Section = styled.section<InlineProps>(cssOverridesTemplateLiteral)
export const _Header = styled.header<InlineProps>(cssOverridesTemplateLiteral)
export const _Input = styled.input<InlineProps>(cssOverridesTemplateLiteral)
export const _Button = styled.button<InlineProps>(cssOverridesTemplateLiteral)
export const _Span = styled.span<InlineProps>(cssOverridesTemplateLiteral)

const GlobalButton = styled(_Button)`
  ${flexMixin}
  ${flexCenterMixin}
  cursor: pointer;
  align-items: center;
  border-radius: 3px;
  border-width: 0;
  outline: none;
  margin: 0;
  line-height: 1rem;
  transition: all 75ms ease 0s;
`

export const Button = styled(GlobalButton) <{ active: boolean }>`
  background: ${({ theme }) => getColor(theme.button.base.background)};
  color: ${({ theme }) => getColor(theme.button.base.color)};

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => getColor(theme.button.active.background)};
      color: ${({ theme }) => getColor(theme.button.active.color)};
    `};

  padding: 0 0.6875rem;
  font-weight: 700;
  height: 1.5rem;
  font-size: 0.75rem;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.85;
    outline: none;
  }
`

export const ActiveButton = styled(Button)`
  background: ${({ theme }) => getColor(theme.button.active.background)};
  color: ${({ theme }) => getColor(theme.button.active.color)};
`

export const Section = styled(_Section)`
  width: 100%;
  max-width: 47.5rem;
  border-bottom: 1px solid rgb(240, 240, 240);
  margin: 0 0 4rem;
  padding: 0 0 3rem;
`

export const Grid = styled(Section) <{ col: number }>`
  display: grid;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  gap: 1rem;
`

export const Wrapper = styled(Section)`
  ${flexMixin};
  justify-content: space-between;
  position: relative;
`

export const Main = styled(_Main)`
  max-width: 75rem;
  margin: 0 auto;
  height: auto;
  min-height: 100vh;
  height: auto;
  background: ${({ theme }) => getColor(Colors.White)};
  border: 1px solid ${({ theme }) => getColor(Colors.LightOutline)};
`

export const Container = styled.article`
  ${flexMixin};
  flex-direction: column;
  flex-grow: 100%;
  width: 100%;
  height: auto;
  overflow: visible
  flex: 1 1 50%;
  grid -column: -1 / 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 47.5rem;
  width: calc(100% - 4rem);
  padding: 0 2rem;
}
`

export const Header = styled(_Header)`
  ${flexMixin};
  ${flexCenterMixin};
  width: 100%;
  justify-content: space-between;
  margin-top: 4rem;
  h1 {
    ${typeface(999)(FontSizes.H1)}
    line-height: 1.65;
  }
`

export const Input = styled(_Input)`
  height: 2.5rem;
  font-size: 1.5rem;
  padding: 0 0.6875rem;
  border-color: rgba(0, 0, 0, 0.12);
  font-size: 0.6875rem;
  line-height: 1rem;
  color: ${({ theme }) => getColor(theme.body)};
  height: 1.5rem;
  min-height: 1.5rem;
  width: 100%;
  border-radius: 3px;
  display: flex;
  border-style: solid;
  border-width: 1px;
  flex: 1 1 0%;
  outline: none;
  padding: 0.25rem 0.375rem;
  transition: all 75ms ease 0s;

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }
  &:active,
  &:focus {
    border-color: ${({ theme }) => getColor(theme.primary)};
  }
`

export const Searchbar = styled(Div)`
  width: 16rem;
`

export const DogeList = styled(Grid) <{ col: number }>`
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
row-gap: 0.75rem;
column-gap: 1rem;
`
export const Image = styled(Div) <{ isFallback?: boolean }>`
  display: table;
  position: relative;
  top: 0;
  button: 0;
  left: 0;
  right: 0;
  & * {
    width: 100%;
    max-width: 100%;
  }
img {

${({ isFallback }) => isFallback && css`
  filter: grayscale(1);
`};
}
`

export const GrayscaleImage = styled(Image)`
`


export const EmptyList = styled(Div)`
  text-align: center;
`

