import React, { ReactChild } from 'react'

import { MindBlownEmoji } from './MindBlownEmoji'

interface Props {
  render?: (error: Error | null) => ReactChild
}

interface State {
  hasError: boolean,
  error: Error | null
}

type Error = string

// From: https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: {}) {
    super(props);
    this.state = { error: null, hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  render() {
    if (this.state.hasError) {
      return this.props.render
        ? this.props.render(this.state.error)
        : <h1>Something went wrong <MindBlownEmoji /> </h1>;
    }

    return this.props.children;
  }
}
