import React, { TransitionStartFunction } from 'react'

import { Doge, DogeSelector, SelectedDogeState } from '../types'
import { SingleDoge } from './SingleDoge'
import * as S from '../styled'

interface Props {
  doge: Doge[]
  selectDoge: DogeSelector
  selectedDoge: SelectedDogeState
  startTransition: TransitionStartFunction
}

const EmptyListFallback: React.FC<{}> = () =>
  <S.EmptyList>
    <S.Text as='h3'>No dogs found!</S.Text>
    <S.Text as='p'>Ohh, you must like some rare breeds!</S.Text><S.Text as='p'>Try a less restrictive filter, maybe?</S.Text></S.EmptyList>

export const DogeList: React.FC<Props> = ({ doge: dogeList, selectDoge, selectedDoge, startTransition }) => {
  return <S.DogeList col={4}>
    {dogeList.length === 0 ? <EmptyListFallback /> : dogeList.map((d: Doge) => <SingleDoge isSelected={d.name === selectedDoge} startTransition={startTransition} selectDoge={selectDoge} key={d.name} oneDoge={d} />)}
  </S.DogeList>
}













