import styled, { css } from 'styled-components'
import { CssOverrides, LibraryProps } from './types'

export const cssOverridesTemplateLiteral = ({ cssOverrides }: Props) => css`${cssOverrides}`

interface Props {
  cssOverrides?: CssOverrides
}

export type InlineProps = LibraryProps & CssOverrides
