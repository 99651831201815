import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Searchbar } from './Searchbar'
import { Query, QuerySetter } from '../types'
import * as S from '../styled'

interface Props {
  query: Query
  setQuery: QuerySetter
}



export const Header = React.memo(({ query, setQuery }: Props) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.Text content='between' as='h1'>Dogs!</S.Text><Searchbar setQuery={setQuery} query={query} />
      </S.Header>
    </S.Wrapper>
  )
})
