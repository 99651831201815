import { Comparator, ComposeSignature, Doge, FilterSignature, IdentitySignature, SortSignature, TakeSignature, Query } from '../types'
export { api } from './api'

export function createResource<A>(asyncFn: () => Promise<A>) {
  let status = 'pending'
  let result: any
  let error: string
  let promise = asyncFn().then(
    r => {
      status = 'success'
      result = r
    },
    e => {
      status = 'error'
      result = e
    },
  )
  return {
    read() {
      if (status === 'pending') throw promise
      if (status === 'error') throw error
      if (status === 'success') return result
      return result
    },
  }
}

export const comparator: Comparator<any> = (a, b) => (a.name < b.name ? -1 : 1)

export const matchNameToQuery = (query: Query) => (a: Doge) => a.name.includes(query)

export const filter: FilterSignature<Doge> = query => list => list.filter(matchNameToQuery(query))

export const sort: SortSignature<Doge> = comparator => list => list.slice().sort(comparator)

export const take: TakeSignature<Doge> = (n: number) => list => list.slice(0, n)

export const identity: IdentitySignature<any> = x => x

export const compose: ComposeSignature = (fns) => data => {
  const reversed = fns.reverse()
  return reversed.reduce((acc, fn) => fn(acc), identity(data))
}
