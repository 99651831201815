import React, { Suspense } from 'react'

import { Image } from '../types'
import { PreloadedImage } from './PreloadedImage'

interface Props {
  image: Image
}

const ImageFallback = () => <PreloadedImage isFallback={true} src="/images/dogePlaceholder.jpg" alt="Image loading..." />

export const SingleImage: React.FC<Props> = ({ image }) => {
  return <Suspense fallback={<ImageFallback />}><PreloadedImage src={image} alt={image} /></Suspense>
}
