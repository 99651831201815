import React, { FormEventHandler } from 'react'

import { Query, QuerySetter } from '../types'
import * as S from '../styled'

interface Props {
  query: Query
  setQuery: QuerySetter
}


export const Searchbar: React.FC<Props> = ({ query, setQuery }) => {
  const handleChange: FormEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.currentTarget.value)
  }

  return (
    <S.Searchbar><S.Input placeholder="Search" value={query} onChange={handleChange} /></S.Searchbar>
  )
}
