import { css, createGlobalStyle } from 'styled-components'
import { AppThemeInterface } from './theme'
import { globalFontMixin } from './typography'

export const GlobalStyle = createGlobalStyle`
${globalFontMixin}
  body {
    height: 100 %;
    width: 100 %;
    padding: 0;
    margin: 0;
    background: rgba(230, 229, 229, 0.15);
  }
  body * {
    box-sizing: border-box;
line-height: 1.45;
  }


  h1, h2, h3, h4, h5, h6, p, body {
    margin: 0px;
    letter-spacing: 0px;
    text-align: start;
    text-decoration: none;
    text-transform: none;
    color: rgb(0,0,0);
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .images-pending {
    opacity: 0.55;
    transition: opacity 0s;
    transition-delay: 0.5s;
  }
`

/*
@font-face {
  font-family: 'unica77';
src: url(/fonts/unica.eot);
src: url(/fonts/unica.eot?#iefix)
format('embedded-opentype'), url(/fonts/unica.woff2)
format('woff2'), url(/fonts/unica.woff)
format('woff');
font-weight: normal;
font-style: normal;
}

@font-face {
  font-family: 'unica77';
src: url(/fonts/unica-italic.eot);
src: url(/fonts/unica-italic.eot?#iefix)
format 'embedded-opentype'), url(/fonts/unica-italic.woff2)
format('woff2'), url(/fonts/unica-italic.woff)
format('woff');
font-weight: normal;
font-style: italic;
}

*/
