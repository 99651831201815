import React from 'react'

import { createResource } from '../utils'
import * as S from '../styled'

// Load image src from browser cache, by creating a fake image that
// preloads the src
const preloadImage = (src: string): Promise<string> => {
  return new Promise(resolve => {
    const img = document.createElement('img')
    img.src = src
    img.onload = () => resolve(src)
  })
}

interface Props {
  src: string
  alt: string
  isFallback?: boolean
}

// This cache is
const imageSrcResourceCache: any = {}

export const PreloadedImage: React.FC<Props> = ({ src, alt, isFallback }) => {
  let imageSrcResource = imageSrcResourceCache[src]
  if (!imageSrcResource) {
    imageSrcResource = createResource(() => preloadImage(src))
    imageSrcResourceCache[src] = imageSrcResource
  }

  return <S.Image isFallback={isFallback}><img src={imageSrcResource.read()} alt={alt} /></S.Image>
}
