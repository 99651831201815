import React, { TransitionStartFunction } from 'react'

import { Doge, DogeSelector } from '../types'
import { SUSPENSE_CONFIG } from '../config'
import * as S from '../styled'

interface Props {
  isSelected: Boolean
  oneDoge: Doge
  selectDoge: DogeSelector
  startTransition: TransitionStartFunction
}

export const SingleDoge: React.FC<Props> = ({ oneDoge, isSelected, selectDoge, startTransition }) => {
  const handleClick = (setter: DogeSelector, name: string) => (_e: any) => {
    startTransition(() => {
      setter(name)
    })
  }


  return <S.Button active={!!isSelected} onClick={handleClick(selectDoge, oneDoge.name)}>{oneDoge.name}</S.Button>
}
